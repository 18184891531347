
.LeftMenu {
  background-color: white;
  overflow: auto;
  height: calc(100vh - 90px);
  padding: 10px;
  background:none;
}
ul {
  background: white !important;
}
.ant-menu-item-selected {
  background-color: green !important; /*Overriden property*/
}